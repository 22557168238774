import axios from 'axios'
import Vue from 'vue'
import router from '../router'

const responseHandler = response => {
  // http response 拦截器
  let data = response.data
  if (data.code === 401) {
    Vue.$cookies.remove('token')
    router.push({
      path: '/login',
      query: {redirect: router.currentRoute.fullPath}
    })
    return Promise.reject(data)
  }
  return data
}

const requestHandler = config => {
  // http request 拦截器
  // 每次请求都为 http 头增加 Authorization 字段，其内容为 Token
  const token = Vue.$cookies.get('token');
  if (token) {
    config.headers.common['Authorization'] = token;
  }
  return config
}

const http = axios.create({baseURL: "/api/v1"})
export const AuthHttp = axios.create({baseURL: "/auth"})

const instances = [http, AuthHttp]

instances.forEach((instance) => {
  instance.interceptors.request.use(requestHandler, error => {
    return Promise.reject(error);
  })
  instance.interceptors.response.use(responseHandler, error => {
    return Promise.reject(error)
  })
})

export default http
