import Login from '../components/Login.vue'
import NotFoundComponent from '../components/NotFoundComponent.vue'

const routes = [
  {
    path: '*',
    component: NotFoundComponent,
    meta: {
      title: "404 页面不存在",
      requireAuth: true
    },
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: "登录"
    }
  }
]

export default routes
