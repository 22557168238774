import Vue from 'vue'
import alertComponent from './alert.vue'
import vuetify from '../../plugins/vuetify'

const AlertConstructor = Vue.extend(alertComponent)

function showAlert (text, type, duration=3000) {
  const alertDom = new AlertConstructor({
    el: document.createElement('div'),
    vuetify,
    data () {
      return { show: true, text: text, type: type}
    }
  })
  let alert = document.getElementById('alert')
  alert.appendChild(alertDom.$el)
  setTimeout(() => {
    alertDom.show = false
    alert.removeChild(alertDom.$el)
  }, duration)
}

export default showAlert
