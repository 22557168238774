import JSEncrypt from 'jsencrypt'
import Vue from 'vue'
import App from './App.vue'
import showAlert from './components/globalAlert/index'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$encrypt = s => {
  const pubKey = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCUSO5V6g81kpft1fORSLyV3+M6
  H4ECIKuLm/pYuI5bMiKtgqmx26XK028xnQGFLufUAEoDF/E4i4G1VE9dy7SFAXMY
  thjtC6J3qkbWhiFtUWofF0kLBxLyFTXjKMh5QAZyOQ66xE15rJ4uu1WiX+3hH6Aq
  64QaEbv6CqsLbLhGhQIDAQAB
  -----END PUBLIC KEY-----`;
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(pubKey);
  return jsEncrypt.encrypt(s.toString());
}
Vue.prototype.$alert = showAlert

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
