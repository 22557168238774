<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
export default {
  props: ['snackbar'],
  inheritAttrs: false,
  data () {
    return {}
  }
}
</script>
