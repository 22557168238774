import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: colors.grey.darken4,
        fileColor: colors.grey.darken3
      },
      light: {
        appBar: "#2A85FF",
        fileColor: colors.lightBlue
      }
    },
  },
});
