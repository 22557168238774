<template>
  <v-container class='d-flex justify-center' style='margin-top: 5rem' app clipped>
      <v-card
        class="pa-5"
        width="23rem"
      >
        <v-row justify="space-around" class="mb-5">
          <v-icon style='font-size: 48px;' color="blue darken-2">
            mdi-account-circle
          </v-icon>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            @keyup.enter="validate"
            v-model="userName"
            :counter="10"
            :rules="userNameRules"
            label="用户名*"
            required
          ></v-text-field>

          <v-text-field
            @keyup.enter="validate"
            v-model="password"
            :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="password_show ? 'text' : 'password'"
            name="input-10-1"
            label="密码*"
            @click:append="password_show = !password_show"
          ></v-text-field>

          <div class="d-flex justify-space-between align-center">
            <v-checkbox
              v-model="checkbox"
              label="记住密码"
              required
            ></v-checkbox>

            <v-btn
              :disabled="!valid"
              color="success"
              @click="validate"
            >
              登录
            </v-btn>
          </div>
        </v-form>
      </v-card>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
        >
          {{ snackbar_text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
  </v-container>
</template>

<script>
import store from '../store'
import { AuthHttp } from '../plugins/http'

  export default {
    data: () => ({
      password_show: false,
      snackbar: false,
      snackbar_text: '',
      valid: true,
      userName: '',
      userNameRules: [
        v => !!v || '请输入注册时用的邮箱或者手机号呀',
        v => (v && v.length <= 10) || '用户名必须少于十个字符'
      ],
      password: '',
      passwordRules: [
        v => !!v || '喵，你没输入密码么？'
      ],
      checkbox: true,
    }),
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          AuthHttp
            .post("login", {
              username: this.userName,
              password: this.$encrypt(this.password)
            })
            .then(response => {
              if (response.code == 0) {
                store.commit("setUserInfo", true)
                store.commit("setLoginStatus", false)
                this.$router.push(this.$route.query.redirect || '/')
              } else {
                this.snackbar = true
                this.snackbar_text = response.msg
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
  }
</script>