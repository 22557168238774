import Vue from "vue"

export function checkToken () {
  const token = Vue.$cookies.get("token")
  return (token && token.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
}

export function getFileSizeStr (value) {
  // 根据字节大小获取可读的文件大小
  if(null == value || value == ""){
      return "0 Bytes"
  }
  var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
  var index = 0;
  var srcsize = parseFloat(value);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  var size = srcsize / Math.pow(1024, index);
  size = size.toFixed(2);
  return `${size} ${unitArr[index]}`;
}

export function getQueryString (name) {
  // 获取 query 属性
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

export function time (time = +new Date()) {
  // 格式化时间戳
  var date = new Date(time * 1000 + 8 * 3600 * 1000);
  return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.');
}

export function isEmptyArray (arr) {
  // 判断是否空数组
  return Array.isArray(arr) && arr.length === 0
}

export function diff(new_obj, old_obj) {
  // 对比两个对象，返回不同的部分
  if (new_obj === old_obj) {return false}
  const obj = {}
  for (const key in new_obj) {
    if (new_obj[key] !== old_obj[key]) {
      obj[key] = new_obj[key]
    }
  }
  return obj
}
