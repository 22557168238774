<template>
  <v-app>
    <v-app-bar
      app
      color="appBar"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="openStatus" :disabled="isLoginPage"></v-app-bar-nav-icon>
      <router-link to="/">
        <div class="d-flex align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            :src="require('../src/assets/icon-logoimg.svg')"
            transition="scale-transition"
            width="40"
          />

          <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="require('../src/assets/icon-logotext.svg')"
            width="100"
          />
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn href="https://vuetifyjs.com/zh-Hans/introduction/why-vuetify/#feature-guides" target="_blank" text>
        <span class="mr-2">文档</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <template v-if="requireAuth">
      <v-navigation-drawer v-model="open_status" app clipped>
        <v-list-item v-if="user_info.name">
          <v-list-item-avatar>
            <img v-if="user_info.avatar" :src="`${user_info.avatar}!avatar`">
            <v-icon v-else style="font-size: 48px">mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-h6">{{ user_info.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user_info.role }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-skeleton-loader
          v-else
          boilerplate
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>

        <v-divider></v-divider>

        <v-list rounded>
          <template v-for="item in leftList">
            <v-list-item v-if='item.single' :key="item.path" :to="item.path">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :value="item.items.map(x => x.path).includes($route.path)"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.path"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-group>
          </template>
        </v-list>
        <template v-if="checkToken()" v-slot:append>
          <div class="pa-2">
            <v-btn block @click="logOut">
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <v-main class="ma-4" app>
        <router-view :key="$route.fullPath"></router-view>
      </v-main>
    </template>
    <template v-else>
      <v-main class="ma-4" app>
        <Login/>
      </v-main>
    </template>
    <div id="alert"></div>
  </v-app>
</template>


<script>
import Login from './components/Login.vue'
import http, { AuthHttp } from './plugins/http'
import store from './store'
import { checkToken } from './plugins/utils'

export default {
  name: 'App',

  components: {
    Login
  },

  data: () => ({
    open_status: window.innerWidth < 600 ? false : true,
    user_info: {
      name: '',
      role: '',
      avatar: ''
    },
  }),
  methods: {
    checkToken,
    logOut() {
      AuthHttp.post("/logout")
        .then(() => {
          this.$cookies.remove("token")
          this.$cookies.remove("role_id")
          this.$router.push("/login")
          this.user_info.avatar = '' // 如不重置，退出再登录别的账号会重复加载此头像
          store.commit("setUserInfo", false)
        }).catch(e => {console.log(e)})
    },
    getUserInfo() {
      if (store.state.login_status || !this.checkToken()) {
        return
      }
      http.get("/get/user_info")
        .then(response => {
          if (response.code === 0) {
            this.user_info.name = response.data.username
            this.user_info.role = response.data.role.role_name_cn
            this.user_info.avatar = response.data.avatar
            let role_id = response.data.role.role_id
            if (this.$cookies.get("role_id") != role_id) {
              this.$cookies.set("role_id", role_id)
            }
          }
        })
        .catch(e => {this.$alert(e.msg, "error")})
    },
    openStatus () {
      this.open_status = !this.open_status
    }
  },
  mounted() {
    if (this.$cookies.get("token")) {
      store.commit("setLoginStatus", false)
    }
    this.getUserInfo()
  },
  watch: {
    '$store.state.user_info': function () {
      this.getUserInfo()
    }
  },
  computed: {
    requireAuth() {
      return !store.state.login_status
    },
    leftList() {
      return store.state.routeList.filter(item => item.visible !== false)
    },
    isLoginPage () {
      return this.$route.path === '/login'
    }
  }
};
</script>

<style>
  @media (min-width: 1024px) {
    body ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }
  body ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: 0 0;
  }
  body ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.2);
  }
  #alert {
    min-width: 300px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 202;
  }

  .v-application--is-ltr .v-data-table__mobile-row__cell {
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
