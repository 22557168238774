import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js'
import store from '../store'
import path, { authControl } from '../plugins/path'
import { checkToken } from '../plugins/utils'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  base: "/"
});

const updateRoute = (item, auth) => {
  router.addRoute({
    path: item.path,
    component: item.component,
    meta: {
      title: item.title,
      requireAuth: item.requireAuth || auth
    }
  })
}

router.beforeEach((to, _from, next) => {
  const checkStatus = checkToken()
  if (to.meta.title) { // 设置 Title
    document.title = `my-app - ${to.meta.title}`
  }
  if (to.meta.requireAuth) {
    if (checkStatus) {
      const roleID = Vue.$cookies.get("role_id")
      const routeList = authControl(path, roleID)
      if (roleID && store.state.routeList.length !== routeList.length) {
        store.commit("setRouteList", routeList)
        routeList.forEach(item => {
          if (item.items) {
            item.items.forEach(ite => {
              if (ite.component) {
                updateRoute(ite, item.requireAuth)
              }
            })
          } else {
            updateRoute(item)
          }
        })
        router.push(to)
      } else {
        const allow = routeList.find(route => {
          if (route.path ) {
            return route.path == to.path
          } else {
            return route.items.find(item => item.path == to.path)
          }
        })
        if (allow || to.path == "/404") next()
        else router.push('/404')
      }
    }
    else {
      store.commit("setLoginStatus", true)
      router.push({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  }
  else {
    store.commit("setLoginStatus", to.path === '/login')
    if (to.path === '/login' && checkStatus) {
      next({path: "/"})
    } else { next() }
  }
});

export default router;
