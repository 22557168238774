<template>
  <v-card
    max-width="600"
    class="mx-auto"
  >
    <v-toolbar
      color="fileColor"
      dark
    >
      <v-toolbar-title>文件管理</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="selectVal"
        hide-details
        label="Search"
        prepend-icon="mdi-magnify"
      ></v-text-field>
    </v-toolbar>
    <div class="d-flex justify-space-between">
      <v-breadcrumbs :items="items"></v-breadcrumbs>
      <v-file-input ref="fileRef" v-show="false" @change="fileChange" multiple></v-file-input>
      <v-btn
        outlined
        color="light-blue"
        class="ma-2"
        @click="uploadFile"
        :loading="loading"
        :disabled="loading"
      >
        上传
        <v-icon right dark>
          mdi-cloud-upload
        </v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <template v-if="emptyFolder">
      <div class="empty-case">
        <img src="../assets/bgm-nodata.png" alt="空盒子" width="387">
      </div>
    </template>
    <v-list
      v-else
      subheader
      two-line
      max-height="calc(100vh - 220px)"
      class="overflow-auto"
    >
      <template v-if="folders.length > 0">
        <v-subheader inset>文件夹</v-subheader>

        <v-list-item
          v-for="folder in folders"
          :key="folder.title"
          @click="folderClick(folder)"
          v-on.stop
        >
          <v-list-item-avatar>
            <v-icon class="grey lighten-1" dark>
              mdi-folder
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="folder.key.substring(0, folder.key.length-1)"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="() => (1)">
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-if="files.length > 0 && folders.length > 0">
        <v-divider inset></v-divider>
      </template>
      <template v-if="files.length > 0">
        <v-subheader inset>文件</v-subheader>

        <v-list-item
          v-for="file in filterFiles"
          :key="file.title"
          :href="setUrl(file.key)"
          target="_blank"
          @click.stop
        >
          <v-list-item-avatar>
            <v-icon
              class="blue"
              dark
              v-text="handlerIcon(file.key)"
            ></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="file.key"></v-list-item-title>

            <v-list-item-subtitle v-text="`${time(file.last_modified)} - ${getFileSizeStr(file.size)}`"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="d-flex flex-row" v-on:click.stop.prevent="onInfo(file)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </template>
              <span>详细信息</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-action class="d-flex flex-row" v-on:click.stop.prevent="onDelete(file.key)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>删除文件</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-list>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <v-dialog v-model="fileInfoShow" width="390">
      <v-card >
        <v-card-title class="text-h6" style="flex-wrap: nowrap;!important;">
          <v-list-item-avatar>
            <v-icon class="blue" dark v-text="fileInfo.icon"></v-icon>
          </v-list-item-avatar>
          {{fileInfo.file_name}}
          <v-spacer></v-spacer>
          <v-btn icon @click="fileInfoShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <div class="my-5"></div>
        <v-card-text>
          <div class="px-3 my-3 d-flex justify-space-between"
            v-for="(item, i) in [['大小', 'file_size'], ['MD5', 'file_md5'], ['创建于', 'last_modified']]"
            :key="i">
            <span v-text="item[0]"></span>
            <span v-text="fileInfo[item[1]]"></span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getFileSizeStr, getQueryString, time, isEmptyArray } from '../plugins/utils'
import http from '../plugins/http'
  export default {
    data: () => ({
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: '',
      folder: '',
      files: [],
      folders: [],
      items: [{
        text: '根',
        disabled: false,
        exact: true,
        to: {path: location.pathname},
      }],
      loading: false,
      emptyFolder: false,
      overlay: false,
      ossUrl: '',
      selectVal: '',
      fileInfoShow: false,
      fileInfo: {
        icon: '',
        file_md5: '',
        file_name: '',
        last_modified: '',
        file_size: 0,
        preview_url: ''
      }
    }),
    created() {
      this.getOSSUrl()
    },
    mounted() {
      this.getOSSInfo()
      this.setItems()
    },
    computed: {
      filterFiles () {
        if(!(this.selectVal ?? '') !== ''){
          return this.files.filter(file => (
            file.key.toLowerCase().indexOf(this.selectVal) >= 0
          ))
        }
        return this.files
      }
    },
    methods: {
      getFileSizeStr,
      getQueryString,
      time,
      isEmptyArray,
      fileChange (file) {
        this.loading = true
        let formData = new FormData();
        file.forEach(f => {
          formData.append('file', f)
        })
        formData.append('path', this.folder)
        http.post('/oss/upload_object', formData, {
          timeout: 300000,
          headers: {
          'Content-Type': 'multipart/form-data'
        }})
          .then(response => {
            if (response.code === 0) {
              let names = response.result.join(", ")
              this.showSnackBar(`${names} ${response.msg}`)
              this.loading = false
              this.getOSSInfo()
            }
          })
          .catch(e => console.log(e))
      },
      uploadFile () {
        this.$refs.fileRef.$refs.input.click()
      },
      getOSSInfo () {
        this.overlay = true
        let path = this.$route.query.path
        return http.get("/oss/list_dir", {params: {path}})
          .then(data => {
            this.overlay = false
            this.folder = data.folder
            this.folders = data.data.folders
            this.files = data.data.files
            if (this.isEmptyArray(this.folders) && this.isEmptyArray(this.files)) {
              this.emptyFolder = true
            }
          })
      },
      handlerIcon (name) {
        let suffix = name.substring(name.lastIndexOf('.') + 1)
        if (['jpg', 'png', 'gif', 'svg', 'jpeg', 'ico'].includes(suffix)) {
          return 'mdi-file-image'
        } else if (['csv', 'xlsx', 'docx', 'txt', 'pdf'].includes(suffix)) {
          return 'mdi-file-document'
        } else if (['pdf', 'xlsx', 'docx', 'txt'].includes(suffix)) {
          return 'mdi-file-document'
        } else if (['zip', 'rar', 'xz', 'tar'].includes(suffix)) {
          return 'mdi-zip-box'
        } else {
          return 'mdi-file'
        }
      },
      folderClick (folder) {
        let route_to = {query: {path: folder.key}}
        this.items = this.items.map((item) => {
          item.disabled = false
          return item
        })
        this.items.push({
          text: folder.key.substring(0, folder.key.length-1),
          disabled: true,
          exact: true,
          to: route_to
        })
        if (this.$route.query.path) {
          // 设置好正确的文件夹地址
          route_to.query.path = this.$route.query.path + folder.key
        }
        this.$router.push(route_to)
      },
      setItems () {
        let path = this.$route.query.path
        if (path) {
          path.split("/").forEach((text, index) => {
            if (text) {
              this.items.push({
                text,
                disabled: false,
                exact: true,
                to: {query: {path: path.split("/").slice(0, index + 1).join("/") + '/'}}
              })
            } 
          })
        }
      },
      setUrl (file_name) {
        return `${this.ossUrl}${this.$route.query.path || ''}${file_name}`
      },
      getOSSUrl () {
        if (localStorage.getItem("oss_url")) {
          this.ossUrl = localStorage.getItem("oss_url")
          return
        }
        http.get("/oss/get_url")
          .then(data => {
            this.ossUrl = data.data.url
            localStorage.setItem("oss_url", data.data.url)
          })
      },
      showSnackBar (text) {
        this.snackbar_text = text
        this.snackbar = true
        setTimeout(() => {
          this.snackbar_text = ''
        }, this.snackbar_timeout)
      },
      onInfo (file) {
        this.fileInfoShow = true
        this.fileInfo.icon = this.handlerIcon(file.key)
        this.fileInfo.file_name = file.key
        this.fileInfo.file_size = this.getFileSizeStr(file.size)
        this.fileInfo.last_modified = this.time(file.last_modified)
        this.fileInfo.file_md5 = file.etag.toLowerCase()
      },
      onDelete (file_name) {
        let file_url = this.setUrl(file_name)
        let objName = (new URL(file_url)).pathname.substr(1)
        http.post("/oss/delete_object", {objName})
          .then(response => {
            if (response.code === 0) {
              this.files = this.files.filter(file => (
                file.key !== file_name
              ))
              this.showSnackBar(response.msg)
            }
          })
          .catch(e => console.log(e))
      },
    },
  }
</script>

<style scoped>
  .empty-case {
    display: flex;
    justify-content: center;
  }
</style>
