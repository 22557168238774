import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    login_status: true,
    user_info: false,
    routeList: []
  },
  mutations: {
    setUserInfo(state, status) {
      state.user_info = status
    },
    setLoginStatus(state, status) {
      state.login_status = status
    },
    setRouteList (state, status) {
      state.routeList = status
    }
  }
});

export default store;
