<template>
  <div>
    <v-row justify="end">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="release-btn"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            fixed
          >
            发布版本
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">填写版本信息</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="form.client_id"
                      :rules="[v => !!v || 'App 号是必填的']"
                      label="App 号*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="form.version_number"
                      :rules="[v => !!v || '版本号是必填的']"
                      label="版本号*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="form.version_code"
                      :rules="[v => !!v || '版本代码是必填的']"
                      label="版本代码*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="form.change_log"
                      outlined
                      label="升级记录"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6" sm="8">
                    <v-file-input
                      v-model="form.update_file"
                      show-size
                      label="APK 文件上传"
                      accept="application/vnd.android.package-archive"
                      :rules="[v => !!v || '文件不见啦']"
                      required
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <v-switch
                      v-model="form.latest"
                      false-value="0"
                      true-value="1"
                      inset
                      label="发布版本"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <small>* 带星号表示必填</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              关闭
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="release"
            >
              发布
            </v-btn>
          </v-card-actions>
          <v-progress-linear
            :active="loading"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>
    <v-container style="max-width: 900px;">
      <v-timeline>
        <v-timeline-item
          v-for="(version, i) in versions"
          :key="i"
          color="cyan"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold cyan--text`"
              v-text="version.update_date"
            ></span>
          </template>
          <div class="py-4">
            <h2 :class="`headline font-weight-light mb-4 cyan--text`" style="display: flex;">
              {{ `${version.version_code} | ${version.version_number} | ${version.client_id}` }}
              <v-icon v-if="version.latest" class="ml-3">mdi-star-shooting</v-icon>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-3" v-bind="attrs" v-on="on" @click="setLatest(version.client_id, version.version_code)">
                    <v-icon dark>mdi-star-outline</v-icon>
                  </v-btn>
                </template>
                <span>设为最新版本</span>
              </v-tooltip>
            </h2>
            <v-divider class="mb-3"></v-divider>
            <div style="white-space: pre-wrap;">{{ version.version_info }}</div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <SnackBar :snackbar="snackbar"></SnackBar>
  </div>
</template>

<script>
import SnackBar from './SnackBar.vue'
import http from '../plugins/http'
  export default {
    components: {
      SnackBar
    },
    data: () => ({
      loading: false,
      snackbar: {
        show: false,
        text: '',
        timeout: 2000
      },
      valid: false,
      dialog: false,
      form: {
        client_id: '',
        version_number: '',
        version_code: '',
        update_file: null,
        change_log: '',
        latest: '0'
      },
      versions: [],
    }),
    mounted () {
      this.getVersionInfo()
    },
    methods: {
      getVersionInfo () {
        http.get("/get/version_info")
          .then(res => {
            this.versions = res.data
          })
          .catch(e => console.log(e))
      },
      release () {
        this.loading = true
        if (this.$refs.form.validate()) {
          let formData = new FormData()
          for (let key in this.form) {
            formData.append(key, this.form[key])
          }
          http.post('/release', formData, {headers: {
            'Content-Type': 'multipart/form-data'
          }})
            .then(res => {
              if (res.code === 0) {
                this.getVersionInfo()
                this.dialog = false
                this.$refs.form.reset()
              }
              this.showSnackBar(res.msg)
            })
            .catch(e => {console.log(e)})
            .finally(() => (this.loading = false))
        }
      },
      setLatest (client_id, version_code) {
        http.post("/set_latest", {
          version_code,
          client_id
        }).then(() => {
          this.getVersionInfo()
          this.showSnackBar("设置成功")
        }).catch(e => {console.log(e)})
      },
      showSnackBar (text) {
        this.snackbar.show = true
        this.snackbar.text = text
        setTimeout(() => {
          this.snackbar.text
        }, this.snackbar.timeout)
      },
    }
  }
</script>

<style lang="scss" scoped>
.release-btn {
  z-index: 1;
  margin-top: 1rem;
  margin-right: 1rem;
}
</style>
